

export default function secondsToHHMMSS(bulkSec) {

	let hours = 0
	let minutes = Math.floor(bulkSec/60)
	let seconds = bulkSec - (minutes * 60)

	if (minutes > 60) {
		hours = Math.floor(minutes/60)
		minutes -= (hours * 60)
	}

	if (minutes < 10) {minutes = '' + '0' + minutes}
	if (seconds < 10) {seconds = '' + '0' + seconds}
	if (hours < 10) {hours = '' + '0' + hours}

	const str = `${hours}:${minutes}:${seconds}`
	return str
}