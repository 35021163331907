import { setDoc, getDoc, getDocs, collection, doc, updateDoc, deleteDoc, arrayUnion, arrayRemove } from "firebase/firestore"
import getDatabaseReference from "../firebase/initialization"
import findLastMatchingLabel from "../functions/findLastMatchingLabel";
import dateToDDMMYYY from "./dateToDDMMYYY";

const db = getDatabaseReference()

async function getAllData() {
	const querySnapshot = await getDocs(collection(db, "sites"));
	let data = {}
	querySnapshot.forEach((doc) => {
		data[doc.id] = {...doc.data()}
	});
	return {sites: data}
}

async function getSites() {

	let allsites = [];
	let data = {};
	const querySnapshot = await getDocs(collection(db, "/sites"))

	querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
		allsites.push(doc.id)
		data[doc.id] = {...doc.data()}
	  });
	
	const activesites = allsites.map((site) => {
		if (data[site].active) {
			return {name: site, active: true, hauls: Object.keys(data[site].hauls).length}
		}
		return {name: site, active: false, hauls: Object.keys(data[site].hauls).length}
	// 	return
	})

	return activesites
}

async function getSiteData(site) {
	const siteRef = doc(db, "sites", site)
	const querySnapshot = await getDoc(siteRef)
	return { site: site, ...querySnapshot.data()}
}

async function getHaulData(site, date) { //data MUST be DDMMYYY
	const siteRef = doc(db, "sites", site)
	const querySnapshot = await getDoc(siteRef)
	const haulData = querySnapshot.data().hauls[date]

	return { site: site, date: date, ...haulData }
}

async function postNewHaul(site, date, supe, mate, note) {
	const docRef = doc(db, "sites", site)
	await updateDoc(docRef, {
		[`hauls.${date}`]: {
			loads: [],
			trucks: [],
			material: mate,
			supervisor: supe,
			notes: note,
			adminOnly: false,
		}
	})
}

async function postNewSite(site) {
	await setDoc(doc(db, "sites", site), {
		active: true,
		hauls: {},
	})
}

async function setSiteActive(site, bool) {
	await updateDoc(doc(db, "sites", site), {
		active: bool
	})
}

async function editSiteName(site, name) {
	const oldData = await getSiteData(site)
	delete oldData.site
	const newRef = doc(db, "sites", name)
	await setDoc(newRef, {...oldData})
	await deleteDoc(doc(db, "sites", site))
}

async function addLoadToHaul(site, date, load) {
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.loads`]: arrayUnion(load)
	})
}

async function subLastLoadFromLabel(site, date, label) {
	const dat = await getHaulData(site, date)
	const loadArray = dat.loads
	const index = findLastMatchingLabel(loadArray, label)
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.loads`]: arrayRemove(index)
	})
}

async function addTruckToHaul(site, date, truck) {
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.trucks`]: arrayUnion(truck)
	})
}

async function removeTruckFromHaul(site, date, label) {
 	const dat = await getHaulData(site, date)
	const oldtruckArray = dat.trucks
	const newTruckArray = oldtruckArray.filter(truck => truck.label != label)
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.trucks`]: newTruckArray
	})
}

async function markHaulFinished(site, date) {
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.adminOnly`]: true
	})
}

async function markHaulOngoing(site, date) {
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.adminOnly`]: false
	})
}

async function updateHaulInfo(site, date, supe, note) {
	await updateDoc(doc(db, "sites", site), {
		[`hauls.${date}.supervisor`]: supe,
		[`hauls.${date}.notes`]: note,
	})
}

export { 
	getAllData, 
	getSiteData, 
	getSites, 
	getHaulData, 
	postNewHaul, 
	postNewSite, 
	setSiteActive, 
	editSiteName, 
	addLoadToHaul, 
	subLastLoadFromLabel, 
	addTruckToHaul, 
	removeTruckFromHaul,
	markHaulFinished,
	markHaulOngoing,
	updateHaulInfo,
}
