import { Button, Stack } from 'react-bootstrap';

export default function DetailsView(props) {
  let textArray = props.haulData.supervisor.split(' ');
  let supervisor = '';
  if (textArray.length >= 2) {
    textArray[0] = textArray[0]
      .charAt(0)
      .toUpperCase()
      .concat(textArray[0].slice(1, textArray[0].length), '');
    textArray[1] = textArray[1]
      .charAt(0)
      .toUpperCase()
      .concat(textArray[1].slice(1, textArray[1].length), '');
    supervisor = textArray[0].concat(' ' + textArray[1]);
  } else {
    supervisor = 'No Supervisor Set';
  }

	const getStyles = () => {
		const desktopStyles = {
			containerStyle: {
				display: 'flex',
				border: '1px solid gray',
				width: '100%',
				height: '50px',
				textAlign: 'left',
				alignContent: 'center',
			},

			titleStyle: {
				width: '25%',
				textAlign: 'center',
				alignContent: 'center',
				fontWeight: 'bold',
				fontSize: '120%',
			},

			textStyle: {
				width: '80%',
				paddingRight: '5px',
				textAlign: 'left',
				alignContent: 'center',
				overflow: 'auto',
			},
		}



		const mobileStyles = {
			containerStyle: {
				display: 'flex',
				border: '1px solid gray',
				height: '50px',
				width: "auto",
				textAlign: 'left',
				alignContent: 'center',
			},

			titleStyle: {
				textAlign: 'center',
				padding: "2px",
				width: "40%",
				alignContent: 'center',
				fontWeight: 'bold',
				fontSize: '120%',
			},

			textStyle: {
				paddingRight: '5px',
				width: "fit-content",
				textAlign: 'left',
				alignContent: 'center',
				overflow: 'auto',
			},
		}
		if (props.isMobile) {
			return mobileStyles
		}
		return desktopStyles
	}


  const PopulateDetailItems = () => {
    const detailItems = [
      { title: 'Site', text: props.haulData.site, large: false },
      { title: 'Date', text: props.haulData.date, large: false },
      { title: 'Supervisor', text: supervisor, large: false },
      {
        title: 'Status',
        text: props.haulData.adminOnly ? 'Finished' : 'Ongoing',
        large: false,
      },
      { title: 'Material', text: props.haulData.material, large: false },
      { title: 'Notes', text: props.haulData.notes, large: true },
    ];

    return detailItems.map((item) => {
			const styles = getStyles()
      if (item.large) {
        styles.containerStyle.height = '150px';
      } 

      return (
        <div style={styles.containerStyle} key={`PopulateDetailItems${item.title}`}>
          <div style={styles.titleStyle}>{item.title}</div>
          <div style={styles.textStyle}>{item.text}</div>
        </div>
      );
    });
  };

  const ToggleFinishedButtons = () => {
    return (
      <div style={{marginTop: "5px"}}>
        <Button
          hidden={props.haulData.adminOnly}
          onClick={() => props.markFinished()}
        >
          Set Finished
        </Button>
        <Button
          hidden={!props.haulData.adminOnly}
          onClick={() => props.markOngoing()}
        >
          Set Ongoing
        </Button>
      </div>
    );
  };
  return (
    <>
      <Stack gap={3}>
        <PopulateDetailItems />
      </Stack>
			<ToggleFinishedButtons />
    </>
  );
}
