import { Modal, Form, FormLabel, FormControl, Button, Badge } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function LogoutModal(props) {
	if (!props.admin) return //Can't log out when you already have!

	const handleKeypress = e => {
		if (e.code === "Enter" && props.login) {
			props.handleLogout();
		}
	}

	return (
		<>
			<span>
				<Link
					style={{
						textDecoration: "none",
						color: "grey",
					}}
					onClick={props.handleShow}
				>
					Logout
					<span style={{
						marginTop: "7px",
						marginLeft: "5px",
						height: "10px",
						width: "10px",
						backgroundColor: props.admin ? "#6c6" : "#c66",
						borderRadius: "50%",
						display: "inline-block"
					}}></span>
				</Link>
			</span>


			<Modal show={props.login} onHide={props.handleCancel}>
				<Modal.Header closeButton>
					<Modal.Title>Admin Logout</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormLabel>Would you like to log out?</FormLabel>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={props.handleCancel}>
						Cancel
					</Button>
					<Button variant="primary" onClick={props.handleLogout}>
						Logout
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}