import { FormLabel, FormSelect, FormControl } from "react-bootstrap";
import { useState } from "react"
import { useLoaderData, useNavigate } from "react-router-dom";
import { getSites, postNewHaul } from "../storage/requests";
import ReactDatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import dateToDDMMYYY from "../storage/dateToDDMMYYY";

export default function NewHaul() {

	const navigate = useNavigate()
	const data = useLoaderData()

	const [site, setSite] = useState("Select Site")
	const [date, setDate] = useState(new Date())
	const [supe, setSupe] = useState("")
	const [mate, setMate] = useState("Clay")
	const [note, setNote] = useState("")

	const handleClose = () => {
		setSite("Select Site")
		setDate(new Date())
		setSupe("")
		setMate("Clay")
		setNote("")
	}

	const addSite = () => {
		const sanDate = dateToDDMMYYY(date)
		postNewHaul(site, sanDate, supe, mate, note)
		handleClose()
		navigate(`/newhaullanding`)
	}

	const SiteOptions = () => {
		let sitearray = data.sites.map((site) => {
			if (site.active) {
				return site.name
			}
			return undefined
		})
		sitearray = sitearray.filter(elem => elem !== undefined)

		const listarray = ["Select Site", ...sitearray]
		return listarray.map((site) => {
			return (<option key={site+"option"} name={site}>{site}</option>)
		})
	}


	return (
		<div style={{ marginLeft: "10%", marginRight: "10%", marginTop: "10px" }}>
			<FormLabel>Select the site you're located on.</FormLabel>
			<FormSelect name="site" value={site} onChange={(e) => setSite(e.target.value)}>
				<SiteOptions />
			</FormSelect>
			<br />

			<FormLabel>Set the current date.</FormLabel>
			<div style={{width: "100px", marginLeft: "calc(50% - 100px)"}}>
				<ReactDatePicker selected={date} onChange={(date) => setDate(date)} />
			</div>
			<br />

			<FormLabel>Enter the name of the site supervisor.</FormLabel>
			<FormControl name="supe" onChange={(e) => setSupe(e.target.value)} ></FormControl>
			<br />

			<FormLabel>Enter the hauled material. If other, specify in notes.</FormLabel>
			<FormSelect name="mate" value={mate} onChange={(e) => setMate(e.target.value)}>
				<option name="Clay">Clay</option>
				<option name="Marginal">Marginal</option>
				<option name="Topsoil">Topsoil</option>
				<option name="Gravel">Gravel</option>
				<option name="Asphalt">Asphalt</option>
				<option name="Concrete">Concrete</option>
				<option name="Other">Other</option>
			</FormSelect>

			<FormLabel>Enter any notes for this haul.</FormLabel>
			<FormControl name="note" onChange={(e) => setNote(e.target.value)} as="textarea" rows={3} ></FormControl>
			<br />

			<Button variant="primary" onClick={addSite}>Add</Button>
		</div>

	)

}

export async function loader() {
	const sites = await getSites();
	return { sites };
}
