

export default function dateToHHMM(date) {
	date = new Date(date)
	let hh = '' + date.getHours()
	let mm = '' + date.getMinutes()

	if (hh.length < 2) {hh = '' + "0" + hh}
	if (mm.length < 2) {mm = '' + "0" + mm}

	const str = `${hh}:${mm}`
	return str
}