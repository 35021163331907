
export const getChartColors = () => {
	const colors = [
		'rgb(255, 000, 000',
		'rgb(000, 255, 000',
		'rgb(000, 000, 255',
		'rgb(255, 255, 000',
		'rgb(255, 000, 255',
		'rgb(000, 255, 255',
		'rgb(255, 099, 132',
		'rgb(099, 255, 132',
		'rgb(132, 099, 255',
		'rgb(132, 099, 132',
		'rgb(099, 099, 132',
		'rgb(255, 000, 000',
		'rgb(000, 255, 000',
		'rgb(000, 000, 255',
		'rgb(255, 255, 000',
		'rgb(255, 000, 255',
		'rgb(000, 255, 255',
		'rgb(255, 099, 132',
		'rgb(099, 255, 132',
		'rgb(132, 099, 255',
		'rgb(132, 099, 132',
		'rgb(099, 099, 132',
	];
	return colors
}