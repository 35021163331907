import { Link, useLoaderData, useOutletContext } from 'react-router-dom';
import {
  editSiteName,
  getAllData,
  getSiteData,
  getSites,
  setSiteActive,
} from '../storage/requests';
import {
  Alert,
  Badge,
  Button,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
  Tab,
  Col,
  CloseButton,
  Fade,
  ButtonGroup,
  Table,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NewSite from '../functions/newSite';
import { calcDetailedHaulData } from '../functions/calculateCubes';

export default function SiteList(props) {
  let navigate = useNavigate();
  const { sites, data } = useLoaderData(); // loader provides list of sites and all data, should be fixed to rely only on data. redundant

  const [all, setAll] = useState(false); // shows or hides inactive sites
  const [alert, setAlert] = useState(false); // warning for showing inactive sites
  const [admin, setAdmin] = useOutletContext(); // import auth

  function handleView() {
    // toggles viewing all sites or only active sites
    if (all) {
      setAll(false);
      setAlert(false);
      return;
    }

    setAll(true);
    setAlert(true);
    return;
  }

  function handleActiveToggle(site) {
    // toggles site between active and inactive. add auth check
    const ob = sites.find((element) => element.name === site);
    if (ob.active) {
      setSiteActive(site, false);
    } else {
      setSiteActive(site, true);
    }
    navigate('/sitelist');
  }

  async function handleEditSiteName(site) {
    // add auth check
    const name = prompt('Enter new site name');
    const conf = window.confirm(`Rename ${site} as ${name}?`);

    if (conf) {
      await editSiteName(site, name);
    }

    navigate('/sitelist');
  }

  const SiteItems = () => {
    // adds another list item to the view for each site

    return sites.map((site) => {
      if (all === false && site.active === false) {
        return;
      }
      return (
        <ListGroupItem
          key={site.name}
          action
          variant={site.active ? 'secondary' : 'danger'}
          eventKey={site.name}
        >
          <div className='d-flex justify-content-around align-items-start'>
            {/* formatting */}

            <span className='p-2 flex-grow-1'>{site.name}</span>
            <span className='p-2'>
              <Badge bg='primary' pill>{`Hauls: ${site.hauls}`}</Badge>
            </span>
          </div>
        </ListGroupItem>
      );
    });
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
  };

  const TabContentsSite = (props) => {
    // generates each site page, viewed only when clicked on.

    const TabTableRows = (props) => {
      // fills out table for viewing all hauls

      let toDisplay = [];
      let dates = Object.keys(data.sites[props.site].hauls).sort() || [];

      dates.map((date) => {
				const siteData = data.sites[props.site].hauls[date]
        if (siteData.trucks === undefined) {
          return;
        }
        const numTrucks = siteData.trucks.length;
        const numLoads = siteData.loads.length;
				const detailData = calcDetailedHaulData(siteData.material, siteData.trucks, siteData.loads)

        let toAdd = (
          <tr key={`${props.site}${date}`}>
            <td>
              <Link to={`/haul/${props.site}/${date}`}>
                {date}
              </Link>
            </td>
						<td>{siteData.material}</td>
            <td>{numTrucks}</td>
            <td>{numLoads}</td>
						<td>{''+ detailData.totalCubes+" m3"}</td>
          </tr>
        );
        toDisplay.push(toAdd);
      });

      return toDisplay;
    };

    return (
      <div>
        <h1>{props.site}</h1>
        <h3>{props.active ? 'Active' : 'Inactive'}</h3>
        <Table responsive striped bordered>
          {/* Table view to see all hauls */}
          <thead>
            <tr>
              <td>Date</td>
							<td>Material</td>
              <td>Trucks</td>
              <td>Loads</td>
							<td>Quantity</td>
            </tr>
          </thead>
          <tbody>
            <TabTableRows site={props.site} />
          </tbody>
        </Table>
        <hr />
        <h3>Controls</h3>
        <ButtonGroup>
          <Button
            variant='primary'
            onClick={() => navigate(`/site/${props.site}`)}
          >
            Site Page
          </Button>
          <Button
            disabled={admin ? false : true}
            variant='outline-primary'
            onClick={() => handleEditSiteName(props.site)}
          >
            Edit Site Name
          </Button>
          <Button
            disabled={admin ? false : true}
            variant='outline-primary'
            onClick={() => handleActiveToggle(props.site)}
          >
            Toggle Inactive status
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  const TabItems = () => {
    return sites.map((site) => {
      if (all === false && site.active === false) {
        return;
      }
      return (
        <Tab.Pane eventKey={site.name} key={site.name}>
          <TabContentsSite site={site.name} active={site.active} />
        </Tab.Pane>
      );
    });
  };

  const handleNewSite = () => {
    // function to add a new site
    const res = NewSite();
    console.log(res);
    if (res == false) {
      return;
    } else {
      navigate('/sitelist');
    }
  };

  return (
    <div className='site-grid'>
      <div
        style={{
          marginLeft: '10px',
        }}
      >
        <Tab.Container id='list-group-tabs-example' defaultActiveKey='#none'>
          <Row>
            <Col sm={3}>
              {/* ALERT FOR INACTIVE SITES */}
              {/* <Collapse in={alert}>
                <div style={{ marginLeft: '20%', marginRight: '20%' }}>
                  <Alert
                    variant='danger'
                    onClose={() => setAlert(false)}
                    dismissible
                  >
                    <Alert.Heading>Inactive sites shown </Alert.Heading>
                    <p>
                      Please note, sites outlined in red below are{' '}
                      <i>inactive</i> and are not editable.
                    </p>
                    <hr />
                    <p>
                      To have a site added or changed to active, please contact
                      Tyler.
                    </p>
                  </Alert>
                </div>
              </Collapse> */}

              {/* LIST VIEW ON LEFT SIDE */}
              <ListGroup>
                <ListGroupItem
                  className='d-flex p-2 justify-content-center'
                  action
                  disabled={admin ? false : true}
                  onClick={() => handleNewSite()}
                  variant={'secondary'}
                >
                  Add New Site
                </ListGroupItem>
                <ListGroupItem
                  className='d-flex p-2 justify-content-center'
                  disabled={admin ? false : true}
                  action
                  onClick={handleView}
                  variant={all ? 'danger' : 'secondary'}
                >
                  {all ? 'Hide Inactive Sites' : 'Show Inactive Sites'}
                </ListGroupItem>
                <SiteItems />
              </ListGroup>
            </Col>

            {/* CONTENT VIEW, RIGHT SIDE */}
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey='#none'>
                  <p>Please select a site.</p>
                </Tab.Pane>
                <TabItems />
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export async function loader({ params }) {
  const sites = await getSites();
  const data = await getAllData();
  return { sites, data };
}
