import React from 'react';
import 'chartjs-adapter-date-fns';
import { Container, Tab, Tabs } from 'react-bootstrap';
import 'chart.js/auto';

import LoadTimeScatterGraph from './graphs/loadtimes';
import RoundTimeLineGraph from './graphs/roundtimesline';
import RoundTimeBarChart from './graphs/roundtimesbar';
import QuantityScatterGraph from './graphs/quantitytimes';
import QuantityBubbleGraph from './graphs/timesplitquantitybubble';

export default function GraphView({ props }) {
  let trucks = props.trucks || [];
  let loads = props.loads || [];
  let material = props.haulData.material;

  return (
    <Container border='primary' style={{marginTop: '10px'}}>
      <Tabs>
        <Tab eventKey='quantityBubble' title='Quantity Bubble'>
          <QuantityBubbleGraph trucks={trucks} loads={loads} material={material} />
        </Tab>
        <Tab eventKey='quantityScatter' title='Quantity Scatter'>
          <QuantityScatterGraph trucks={trucks} loads={loads} material={material} />
        </Tab>
        <Tab eventKey='roundTimeLine' title='Round Time Line'>
          <RoundTimeLineGraph trucks={trucks} loads={loads} />
        </Tab>
        <Tab eventKey='roundTimeBar' title='Round Time Bar'>
          <RoundTimeBarChart trucks={trucks} loads={loads} />
        </Tab>
      </Tabs>
    </Container>
  );
}
