const data = {
	sites: {
		Ambleside: {
			active: true,
			hauls: {
				"15-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
					],
				},
				"14-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
					],
				},
			},
		},
		Border: {
			active: true,
			hauls: {
				"12-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
			},
		},
		Paisley: {
			active: true,
			hauls: {
				"12-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
			},
		},
		Maple: {
			active: true,
			hauls: {
				"16-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
				"15-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
				"14-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
				"13-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
				"12-09-2022": {
					supervisor: 'Tyler Werkman',
					material: 'Clay',
					notes: 'Another regular day, my man...',
					trucks: [
						{label: '1234', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1235', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1236', type: 'Quad', color1: 'color1', color2: 'color2'},
						{label: '1237', type: 'Quad', color1: 'color1', color2: 'color2'},
					],
					loads: [
						{label: '1234', time: new Date()},
						{label: '1235', time: new Date()},
						{label: '1236', time: new Date()},
						{label: '1237', time: new Date()},
					],
				},
			},
		},
	}
}

function getExampleData() {
	return data
}

export {getExampleData}
