import { useEffect, useState } from "react"
import { Alert, Collapse, Table } from "react-bootstrap"
import { Button } from "react-bootstrap"
import dateToHHMM from "../../../storage/dateToHHMM"
import secondsToHHMMSS from "../../../storage/secondsToHHMMSS"
import secondsToMMSS from "../../../storage/secondsToMMSS"

function SignoutTableMain(props) {

	const tableMain = {
		margin: "10px",
		marginTop: "10px",
		border: "1px solid grey",
		textAlign: "center",
	}

	const tableHeadRow = {
		border: "1px solid grey",
	}


	return (
		<Table bordered hover responsive style={tableMain}>
			<thead>
				<tr>
					<th style={tableHeadRow}>Label</th>
					<th style={tableHeadRow}>Type</th>
					<th style={tableHeadRow}>Loads</th>
					<th style={tableHeadRow}>First Load</th>
					<th style={tableHeadRow}>Last Load</th>
					<th style={tableHeadRow}>Total Hours</th>
					<th style={tableHeadRow}>Trucking Company</th>
				</tr>
			</thead>
			<tbody>
				{props.children}
			</tbody>
		</Table>
	)
}

function SignoutTableRow(props) {

	const tableCell = {
		border: "1px solid grey",
	}

	const labelBorderColor = {
		border: "1px solid grey",
		background: "linear-gradient(to right," + props.color1 + " 50%, " + props.color2 + " 50%)"
	}

	const labelWhiteBack = {
		border: "1px solid grey",
		margin: "10px",
		padding: "0px",
		backgroundColor: "white"
	}

	const FirstLoad = () => {
		if (props.list.length === 0) { return "No Loads" }
		const date = props.list[0].time
		const time = dateToHHMM(date)
		return time
	}

	const LastLoad = () => {
		if (props.list.length === 0) { return "No Loads" }
		const date = props.list[props.list.length - 1].time
		const time = dateToHHMM(date)
		return time
	}

	const TotalHours = () => {
		if (props.list.length === 0) { return "No Loads" }
		if (props.list.length === 1) { return "One Load" }

		const first = props.list[0].time
		const last = props.list[props.list.length - 1].time

		const total = Math.floor((last - first) / 1000)

		const str = secondsToHHMMSS(total)
		return str
	}


	return (
		<tr key={"row" + props.label}>
			<td style={labelBorderColor}>
				<div style={labelWhiteBack}>
					{props.label}
				</div>
			</td>
			<td style={tableCell}>{props.type}</td>
			<td style={tableCell}>{props.loads}</td>
			<td><FirstLoad /></td>
			<td><LastLoad /></td>
			<td><TotalHours /></td>
			<td>{props.company}</td>
		</tr>
	)
}

function NotFinished(props) {
	return (
		<Collapse in={props.show}>
			<div style={{ marginLeft: "10%", marginRight: "10%" }}>
				<Alert dismissible variant="warning" onClose={() => props.setShow(false)}>
					<Alert.Heading>Haul Unfinished</Alert.Heading>
					<p>Please be aware, this haul is not yet marked as <b>finished</b>.</p>
					<p>When all signout is completed, please mark this haul as finished through the <i>Haul Info</i> menu, link above.</p>
				</Alert>
			</div>
		</Collapse>
	)
}

export default function SignoutTableHandler({ props }) {

	const [show, setShow] = useState((props.haulData.adminOnly?false:true))

	const trucks = props.trucks
	const loads = props.loads

	const numLoads = (label) => {
		let num = 0
		loads.map((load) => {
			if (load.label === label) {
				num++
			}
		})
		return num
	}

	const loadList = (label) => {
		let list = []
		loads.map((load) => {
			if (load.label === label) {
				const date = load.time.toDate()
				list.push({ label: load.label, time: date })
			}
		})

		return [...list]
	}

	const populateRows = trucks.map((truck) => {
		return <SignoutTableRow
			key={truck.label}
			finished={props.haulData.adminOnly}
			label={truck.label}
			type={truck.type}
			loads={numLoads(truck.label)}
			list={loadList(truck.label)}
			color1={truck.color1}
			color2={truck.color2}
			company={truck.company || "Not listed"}
			addLoad={props.addLoad}
			subLoad={props.subLoad}
			perm={props.perm}
		/>
	})

	return (
		<div>
			<NotFinished adminOnly={props.haulData.adminOnly} show={show} setShow={setShow} />
			<SignoutTableMain>
				{populateRows}
			</SignoutTableMain>
		</div>

	)
}