import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyCV2YtMUZmAshPLt_QV_5NkJRiCCgSB8SM",
	authDomain: "truckcount-testdb.firebaseapp.com",
	databaseURL: "https://truckcount-testdb-default-rtdb.firebaseio.com",
	projectId: "truckcount-testdb",
	storageBucket: "truckcount-testdb.appspot.com",
	messagingSenderId: "944411923179",
	appId: "1:944411923179:web:ab321854f867dd8f4964ae",
	measurementId: "G-5XDZTKCMLB"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app)
export function getAnalyticsReference() {
	return analytics
}

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export default function getDatabaseReference() {
	return db
}

const auth = getAuth();

export function getAuthReference() {
	return auth
}