import { NavLink, Navbar, NavbarBrand, Nav, Container } from 'react-bootstrap';

import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useOutletContext } from 'react-router-dom';

import { onSnapshot, doc } from 'firebase/firestore';
import getDatabaseReference from '../firebase/initialization';

import { addLoadToHaul, addTruckToHaul, getHaulData, markHaulFinished, markHaulOngoing, removeTruckFromHaul, subLastLoadFromLabel, updateHaulInfo } from '../storage/requests';
import HaulViewHandler from '../components/haulView/HaulViewHandler';
import checkEditPermission from '../functions/checkEditPermission';
import InfoModal from '../components/modal/info';
import { CSVLink } from 'react-csv';

export default function Haul() {
  const { loaderData } = useLoaderData();

  const [expanded, setExpanded] = useState(false);
  const [info, setInfo] = useState(false);
  const [haulData, setHaulData] = useState({ ...loaderData });
	const [width, setWidth] = useState(window.innerWidth)
  const [admin, setAdmin] = useOutletContext();

  useEffect(() => {
    //Realtime updates of haul data.
    const db = getDatabaseReference();
    const docRef = doc(db, 'sites', loaderData.site);
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const data = snap.data();
      setHaulData({ ...data.hauls[loaderData.date], site: loaderData.site, date: loaderData.date, loads: data.hauls[loaderData.date].loads.sort((a,b) => a.time - b.time) });
    });

    return () => unsubscribe();
  }, []);

	function handleWindowSizeChange() {
    setWidth(window.innerWidth);
	}
	useEffect(() => {
			window.addEventListener('resize', handleWindowSizeChange);
			return () => {
					window.removeEventListener('resize', handleWindowSizeChange);
			}
	}, []);
	
  const [newSupe, setNewSupe] = useState(haulData.supervisor);
  const [newNote, setNewNote] = useState(haulData.notes);

  const navigate = useNavigate();
  const finished = haulData.adminOnly || false;
  const perm = checkEditPermission(haulData.site, haulData.date, admin, finished); //true if able to edit, false if not.

  const handleShow = () => {
    setExpanded(false);
    setInfo(true);
  };

  const handleCancel = () => {
    setNewNote('');
    setNewSupe('');
    setInfo(false);
  };

  const handleSave = () => {
    updateHaulInfo(haulData.site, haulData.date, newSupe, newNote);
    setNewNote('');
    setNewSupe('');
    setInfo(false);
    navigate(`/haul/${haulData.site}/${haulData.date}`);
  };

  const addLoad = (label) => {
    if (perm === false) {
      return;
    }
    const load = {
      label: label,
      time: new Date(),
    };
    addLoadToHaul(haulData.site, haulData.date, load);
  };

  const subLoad = (label) => {
    if (perm === false) {
      return;
    }
    subLastLoadFromLabel(haulData.site, haulData.date, label);
  };

  const addTruck = (truck) => {
    if (perm === false) {
      return;
    }
    addTruckToHaul(haulData.site, haulData.date, truck);
  };

  const removeTruck = (label) => {
    if (perm === false) {
      return;
    }
    const conf = window.confirm(`Remove truck with label ${label}? This is a permanent decision and cannot be undone.`);
    if (conf === false) {
      return;
    }
    removeTruckFromHaul(haulData.site, haulData.date, label);
  };

  const markFinished = () => {
    const conf = window.confirm(
      'Mark haul as completed? This will lock any further edits of trucks or loads. Hauls can only be unlocked by administrators. Please only use once all trucks have been signed out.'
    );
    if (conf === false) {
      return;
    }
    markHaulFinished(haulData.site, haulData.date);
  };

  const markOngoing = () => {
    if (perm === false) {
      alert('Must be logged in to edit finished hauls.');
      return;
    }
    markHaulOngoing(haulData.site, haulData.date);
  };

  const GraphButton = () => {
    const shownButton = (
      <NavLink onClick={() => setExpanded(false)} href='#Graphs'>
        Graphs
      </NavLink>
    );
    const hiddenButton = (
      <NavLink hidden onClick={() => setExpanded(false)} href='#Graphs'>
        Graphs
      </NavLink>
    );

    if (!perm) {
      return hiddenButton;
    }
    return shownButton;
  };

  const DevCSV = () => {
    const csvTruckData = [['label', 'type', 'company', 'color1', 'color2']];

    haulData.trucks.map((truck) => {
      csvTruckData.push([truck.label, truck.type, truck.company, truck.color1, truck.color2]);
    });

    const csvLoadData = [['label', 'time']];
    haulData.loads.map((load) => {
      csvLoadData.push([load.label, load.time.toDate().toUTCString()]);
    });

    return (
      <>
        {/* DEV ONLY PLEASE REMOVE */}
        <CSVLink data={csvTruckData}>CSV Trucks</CSVLink>
        <CSVLink data={csvLoadData}>CSV Loads</CSVLink>
      </>
    );
  };

  return (
    <div>
      <Navbar bg='light' expand='lg' expanded={expanded}>
        <Container>
          <NavbarBrand>
            <b>{perm ? 'Menu' : 'Menu | Haul Edits Locked'}</b>
          </NavbarBrand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' onClick={() => setExpanded(expanded ? false : 'expanded')} />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              <NavLink onClick={() => setExpanded(false)} href='#AddTruck'>
                Add Truck
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} href='#Card'>
                Card View
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} href='#Table'>
                Table View
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} href='#Signout'>
                Signout View
              </NavLink>
              <GraphButton />
              <NavLink onClick={() => setExpanded(false)} href='#Info'>
                Haul Info
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <InfoModal
        note={haulData.notes}
        supe={haulData.supervisor}
        newNote={newNote}
        newSupe={newSupe}
        setNewNote={setNewNote}
        setNewSupe={setNewSupe}
        info={info}
        handleCancel={handleCancel}
        handleSave={handleSave}
      />
      <HaulViewHandler
        perm={perm}
				width={width}
        markFinished={markFinished}
        markOngoing={markOngoing}
        removeTruck={removeTruck}
        addTruck={addTruck}
        addLoad={addLoad}
        subLoad={subLoad}
        trucks={haulData.trucks}
        loads={haulData.loads}
        haulData={haulData}
        handleShow={handleShow}
        newNote={newNote}
        newSupe={newSupe}
      />
    </div>
  );
}

export async function loader({ params }) {
  let loaderData = await getHaulData(params.siteID, params.dateID);
  return { loaderData };
}
