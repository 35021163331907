

export default function NewHaulLanding() {
	return(
		<div style={{
			textAlign: "center",
			margin: "5%",
			padding: "15px",
			paddingTop: "10%",
			paddingBottom: "10%",
			border: "1px solid grey",
			borderRadius: "50px"
		}}>
			<h1>Done!</h1>
			<p>Your new haul has been created. Please allow up to 30 seconds for the server to sync. You can access your new haul through the navigation buttons above.</p>
		</div>
	)
}