import SiteFooter from '../components/footer';

export default function Landing() {
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          margin: '5%',
          padding: '15px',
          marginBottom: '0px',
          paddingBottom: '0px',
          border: '1px solid grey',
          borderRadius: '50px',
        }}
      >
        <div
          style={{
            marginTop: '10%',
            marginBottom: '5%',
            padding: '15px',
          }}
        >
          <h1>Hello!</h1>
          <br />
          <p>Please use the navigation buttons at the top to continue.</p>
          <p>
            If you've been asked to use this site to count trucks, start by
            clicking <i>New Haul</i> at the top of the page.
          </p>
					<p>
						If you have any questions, get in touch with Tyler.
					</p>
        </div>
      </div>
      {/* <SiteFooter /> */}
    </div>
  );
}
