import { useState } from "react"
import { Modal, Form, FormLabel, FormControl, Button, Badge, Alert, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function LoginModal(props) {

	const [load, setLoad] = useState(false)
	if (props.admin) return //then you'd be logging out

	const handleKeypress = e => {
		if (e.code === "Enter" && props.login) {
			props.handleLogin();
		}
	}

	const handleLoginStart = () => {
		setLoad(true)
		props.handleLogin()
		setLoad(false)
	}

	return (
		<>
			<span>
				<Link
					style={{
						textDecoration: "none",
						color: "grey",
					}}
					onClick={props.handleShow}
				>
					Login
					<span style={{
						marginTop: "7px",
						marginLeft: "5px",
						height: "10px",
						width: "10px",
						backgroundColor: props.admin ? "#6c6" : "#c66",
						borderRadius: "50%",
						display: "inline-block"
					}}></span>
				</Link>
			</span>


			<Modal show={props.login} onHide={props.handleCancel}>
				<Modal.Header closeButton>
					<Modal.Title>Admin Login</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormLabel>Email</FormLabel>
						<FormControl autoComplete="email" placeholder="email@example.com" onChange={(e) => props.setUsername(e.target.value)} onKeyDown={handleKeypress} />
						<br />
						<FormLabel>Password</FormLabel>
						<FormControl autoComplete="current-password" type="password" placeholder="Password" onChange={(e) => props.setPassword(e.target.value)} onKeyDown={handleKeypress} />
					</Form>
					<br />
					<Alert variant="danger" hidden={!props.loginAlert}>
						<p>Your username or password is incorrect.</p>
					</Alert>
				</Modal.Body>
				<Modal.Footer>

					<Button variant="secondary" onClick={props.handleCancel}>
						Cancel
					</Button>
					<Button disabled={load ? true : false} variant="primary" onClick={handleLoginStart}>
						{load ? (<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
						/>) : "Login"}
					</Button>

				</Modal.Footer>
			</Modal>
		</>
	)
}