

export default function dateToDDMMYYY(date) {
	date = new Date(date)
	let dd = '' + date.getDate()
	let mm = '' + (date.getMonth()+1)
	let yyyy = '' + date.getFullYear()

	if (dd.length < 2) {dd = '' + "0" + dd}
	if (mm.length < 2) {mm = '' + "0" + mm}

	const str = `${dd}-${mm}-${yyyy}`
	return str
}