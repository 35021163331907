import React from 'react';
import 'chartjs-adapter-date-fns';
import { Bubble, Scatter } from 'react-chartjs-2';
import 'chart.js/auto';
import { getChartColors } from './colors';
import { getLoadSize } from '../../../../functions/calculateCubes.js'


function loadQuantityDatasets( trucks, loads, material ) {
	const colors = getChartColors()
  let newDatasets = [];
	let newLoads = [];
  let totalQuantity = 0;

	loads.forEach((load, index) => {
		const type = trucks.filter((truck) => truck.label == load.label)[0].type
		const size = getLoadSize(type, material);
		totalQuantity += size;
		newLoads.push({...load, size: size, runningTotal: totalQuantity});
	})

  trucks.forEach((truck, index) => {
    const loadTimes = [];
    const loadArray = newLoads.filter((load) => load.label == truck.label).sort((a, b) => a.index - b.index);
	
    loadArray.forEach((load) => {
      loadTimes.push({ x: load.time.toDate(), y: load.runningTotal });
    });

    const newDatasetItem = {
      label: truck.label,
      data: loadTimes,
      borderColor: colors[index] + ')',
      backgroundColor: colors[index] + ', 0.5)',
    };
    newDatasets.push(newDatasetItem);
  });
  return newDatasets;
}

export default function QuantityScatterGraph({trucks, loads, material}) {
	// return 
  const DELTA = 5;
  const datasets = loadQuantityDatasets(trucks, loads, material);

  const options = {
    responsive: true,
    scales: {
      y: {
        title: { display: true, text: 'Post-load Total Quantity (m3)' },
      },
      x: {
        type: 'time',
        time: {
          unit: 'hour',
        },
        title: { display: true, text: 'Time of Load' },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total Quantity by Time of Load',
      },
    },
  };

  const data = { datasets: datasets };
  return <Scatter id='quantitytimes' data={data} options={options} />;
};
