import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Root, { loader as rootLoader } from './routes/root';
import Site, { loader as siteLoader } from './routes/site';
import Haul, { loader as haulLoader } from './routes/haul';
import All, { loader as allLoader } from './routes/all';
import SiteList, { loader as listLoader } from './routes/sitelist';
import NewHaul, { loader as newLoader } from './routes/newhaul';

import Landing from './routes/landing';
import ErrorPage from './errorpage';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
	createBrowserRouter,
	RouterProvider,
	Route,
} from "react-router-dom";
import NewHaulLanding from './routes/newhaullanding';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <ErrorPage />,
		loader: rootLoader,
		children: [
			{
				path: '/sitelist',
				element: <SiteList />,
				loader: listLoader,
			},
			{
				path: '/site/:siteID',
				element: <Site />,
				loader: siteLoader,
			},
			{
				path: '/haul/:siteID/:dateID',
				element: <Haul />,
				loader: haulLoader,
			},
			{
				path: '/all',
				element: <All />,
				loader: allLoader,
			},
			{
				path: '/newhaul',
				element: <NewHaul />,
				loader: newLoader,
			},
			{
				path: '/newhaullanding',
				element: <NewHaulLanding />,
			},
			{
				path: '/',
				element: <Landing />
			},
		]
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
