import { Container, Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { getSites } from '../storage/requests';
import { useState } from 'react';
import LoginModal from '../components/modal/login';
import LogoutModal from '../components/modal/logout';
import {
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getAuthReference } from '../firebase/initialization';

export default function Root() {
  const [admin, setAdmin] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginAlert, setLoginAlert] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // if (window.location.hostname==="localhost" && admin===false) {setAdmin(true)} // sets login to always enabled, testing only.

  
  // AUTH FUNCTIONS


  const auth = getAuthReference();

  signInAnonymously(auth)
    .then(() => {})
    .catch((error) => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // ...
    });

  const handleShow = () => {
    setLoginAlert(false);
    setLogin(true);
    setExpanded(false);
  };
  const handleCancel = () => {
    setUsername('');
    setPassword('');
    setLoginAlert(false);
    setLogin(false);
  };

  const handleLogin = () => {
    signOut(auth)
      .then(() => {
        signInWithEmailAndPassword(auth, username, password)
          .then((userCredential) => {
            // Signed in
            setAdmin(true);
            handleCancel();
            return true;
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setLoginAlert(true);
            setAdmin(false);
            return false;
            // ..
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setLoginAlert(true);
        setAdmin(false);
        return false;
        // ..
      });
  };

  const handleLogout = () => {
    setAdmin(false);
    handleCancel();
  };

  //info for navbar to loop through
  const navItems = {
    Home: '/',
    'New Haul': '/newhaul',
    'All Hauls': '/all',
    'Site List': '/sitelist',
  };

  const NavLinks = () => {
    let toRet = [];
    for (const key in navItems) {
      const em = (
        <Link
          key={key}
          id='RouterNavLink'
          to={navItems[key]}
          onClick={() => setExpanded(false)} //Closes the drop-down for small screens
          style={{
            textDecoration: 'none',
            color: 'grey',
            marginRight: '1rem',
          }}
        >
          {key}
        </Link>
      );
      toRet.push(em);
    }

    return toRet;
  };

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'block',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <div>
        <Navbar bg='light' expand='lg' expanded={expanded}>
          <Container>
            <NavbarBrand>
              <b>Truck Counting</b>
            </NavbarBrand>
            <Navbar.Toggle
              aria-controls='basic-navbar-nav'
              onClick={() => setExpanded(expanded ? false : 'expanded')}
            />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto'>
                <NavLinks />
                <LoginModal
                  admin={admin}
                  login={login}
                  loginAlert={loginAlert}
                  setUsername={setUsername}
                  setPassword={setPassword}
                  handleShow={handleShow}
                  handleCancel={handleCancel}
                  handleLogin={handleLogin}
                  handleLogout={handleLogout}
                />
                <LogoutModal
                  admin={admin}
                  login={login}
                  setUsername={setUsername}
                  setPassword={setPassword}
                  handleShow={handleShow}
                  handleCancel={handleCancel}
                  handleLogin={handleLogin}
                  handleLogout={handleLogout}
                />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div>
        <hr />
        <Outlet context={[admin, setAdmin]} />
      </div>
    </div>
  );
}

export async function loader() {
  const sites = await getSites();
  return { sites };
}
