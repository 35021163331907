import { Button, Table } from 'react-bootstrap';
import { Link, useLoaderData } from 'react-router-dom';
import { getSiteData } from '../storage/requests';
import { calcDetailedHaulData } from '../functions/calculateCubes';

const HaulLinks = ({ siteData }) => {
  let HaulLinkArray = [];
  let haulCount = 0;
  let hauls = Object.keys(siteData.hauls);
  hauls = hauls.sort();
  hauls.forEach((date) => {
    const toAdd = (
      <div
        key={`haulLink${siteData.site}${date}`}
        style={{ marginBottom: '10px' }}
      >
        <Link to={`/haul/${siteData.site}/${date}`}>
          <Button variant='outline-secondary'>{date}</Button>
        </Link>
      </div>
    );
    HaulLinkArray.push(toAdd);
    haulCount++;
  });

  return (
    <>
      <p>Hauls: {haulCount}</p>
      {HaulLinkArray}
    </>
  );
};

const HaulTableMain = (props) => {
  return (
    <Table bordered hover responsive>
      <thead>
        <tr>
          <th rowSpan={2}>Date</th>
          <th rowSpan={2}>Material</th>
					<th colSpan={4}>Trucks</th>
          <th colSpan={4}>Loads</th>
					<th colSpan={4}>Cubes</th>
					<th colSpan={2}>Total</th>
        </tr>
        <tr>
          <th>Tandem</th>
          <th>End Dump</th>
          <th>Tridem</th>
          <th>Quad</th>
          <th>Tandem</th>
          <th>End Dump</th>
          <th>Tridem</th>
          <th>Quad</th>
          <th>Tandem</th>
          <th>End Dump</th>
          <th>Tridem</th>
          <th>Quad</th>
					<th>Trucks</th>
					<th>Cubes</th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  );
};


const HaulTableRow = ({ site, date, material, trucks, loads }) => {

	const DateLink = () => {
		return (
			<Link to={`/haul/${site}/${date}`}>
				<Button variant='outline-secondary'>{date}</Button>
			</Link>
		)
	}
	
	const totalTrucks = trucks.length
	const calcData = calcDetailedHaulData(material, trucks, loads)

  return (
    <tr>
      <td><DateLink/></td>
      <td>{material}</td>
			<td>{calcData.sortedTrucks.Tandems.labels.length}</td>
			<td>{calcData.sortedTrucks.Enddumps.labels.length}</td>
			<td>{calcData.sortedTrucks.Tridems.labels.length}</td>
			<td>{calcData.sortedTrucks.Quads.labels.length}</td>
      <td>{calcData.tandemLoads}</td>
      <td>{calcData.enddumpLoads}</td>
      <td>{calcData.tridemLoads}</td>
      <td>{calcData.quadLoads}</td>
			<td>{calcData.tandemCubes}</td>
			<td>{calcData.enddumpCubes}</td>
			<td>{calcData.tridemCubes}</td>
			<td>{calcData.quadCubes}</td>
			<td style={{fontWeight: "bold"}}>{totalTrucks}</td>
			<td style={{fontWeight: "bold"}}>{calcData.totalCubes}</td>
    </tr>
  );
};

const HaulTableHandler = (props) => {
  const PopulateHaulTableRows = () => {
		let siteArray = Object.keys(props.siteData.hauls)
		siteArray.sort()
    return siteArray.map((key) => {
      const haul = props.siteData.hauls[key];
      return (
        <HaulTableRow
					key={`haultablerowkey${key}`}
					site={props.siteData.site}
          date={key}
          material={haul.material}
          trucks={haul.trucks}
          loads={haul.loads}
        />
      );
    });
  };

  return (
    <HaulTableMain>
      <PopulateHaulTableRows />
    </HaulTableMain>
  );
};

export default function Site() {
  const { siteData } = useLoaderData();
  return (
    <div>
      <h1>{siteData.site}</h1>
      {siteData.active ? null : <h3 style={{ color: 'red' }}>INACTIVE</h3>}
      <h6>
        <i>Dates formatted DD-MM-YYYY</i>
      </h6>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%', margin: '5%' }}>
          <HaulTableHandler siteData={siteData} />
        </div>
        {/* <div style={{ width: '40%' }}>
          <HaulLinks siteData={siteData} />
        </div> */}
      </div>
    </div>
  );
}

export async function loader({ params }) {
  const siteData = await getSiteData(params.siteID);
  return { siteData };
}
