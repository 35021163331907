import React from 'react';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { getChartColors } from './colors';

export default function RoundTimeBarChart({ trucks, loads }) {
	const colors = getChartColors()
  let labels = trucks.map((truck) => {
    return truck.label;
  });
  let truckAverages = [];
  let dataset = [];
  trucks.forEach((truck, index) => {
    let newLoads = loads.filter((load) => load.label == truck.label);
    let total = 0;
    let count = 0;
    for (let i = 1; i < newLoads.length; i++) {
      const time = newLoads[i].time - newLoads[i - 1].time;
      total = total + time;
      count++;
    }
    const newAverage = {
      label: truck.label,
      data: total / count / 60,
    };
    truckAverages.push(newAverage);
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Average Round Times',
      },
    },
  };

  const populatableDataRAW = truckAverages.sort((a, b) => labels.indexOf(a.label) - labels.indexOf(b.label));
  const populatableData = populatableDataRAW.map((item) => {
    return item.data;
  });

  const datasets = {
    label: 'Average',
    data: populatableData,
    borderColor: 'rgb(255, 000, 000)',
    backgroundColor: 'rgb(255, 000, 000, 0.5)',
  };

  const data = {
    labels,
    datasets: [datasets],
  };
  return <Bar id={'averages'} data={data} options={options} />;
};
