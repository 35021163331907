import { Card, Button } from "react-bootstrap"
import { useState } from "react"
import "./card.css"
import dateToHHMM from "../../../storage/dateToHHMM"
import { Timestamp } from "firebase/firestore"

function TruckCard(props) {

	const [hidden, setHidden] = useState(true)
	const cardWrapper = {
		display: "inline-block",
		verticalAlign: "top",
		width: "auto",
		minWidth: "150px",
		margin: "10px",
		// padding: "10px",
		border: "1px solid grey",
		borderRadius: "5px",
		background: "linear-gradient(to top," + props.color1 + " 50%, " + props.color2 + " 50%)"
	}

	const cardBorder = {
		margin: "10px",
		padding: "10px",
		border: "1px solid grey",
		borderRadius: "5px",
		background: "white"
	}

	const handleExpanded = () => {
		if (hidden == false) {
			setHidden(true)
		} else {
			setHidden(false)
		}
	}

	const ListItems = () => {
		let count = 0
		return(
			props.list.map((load) => {
				count++
				return(
					<li key={load.label+load.time+count}>{load.time}</li>
				)
			})
		)
	}

	return (
		<div style={cardWrapper}>
			<div style={cardBorder}>
				<div className="card-label"><b>{props.label}</b></div>
				<div className="card-type">{props.type}</div>
				<div className="card-loads">Loads: {props.loads}</div>
				<div className="card-buttons">
					<Button disabled={props.perm?false:true} variant="outline-primary" onClick={() => props.addLoad(props.label)} >+</Button>
					{" "}
					<Button disabled={props.perm?false:true} variant="outline-primary" onClick={() => props.subLoad(props.label)} >-</Button>
					{" "}
					<br />
					{" "}
					<div style={{marginTop: "5px"}}>
						<Button size="sm"  variant="outline-primary" onClick={handleExpanded}>{hidden ? "Show" : "Hide"}</Button>
					</div>
				</div>

				<div className="card-expanded" hidden={hidden}>
					<hr />
					<ol><ListItems /></ol>
					<Button disabled={props.perm?false:true} variant="danger" onClick={() => props.removeTruck(props.label)}>DELETE</Button>
				</div>
			</div>
		</div>
	)
}

export default function CardView({props}) {

	const trucks = props.trucks || []
	const loads = props.loads || []

	const numLoads = (label) => {
		let num = 0
		loads.map((load) => {
			if (load.label === label) {
				num++
			}
		})
		return num
	}

	const loadList = (label) => {
		let list = []
		loads.map((load) => {
			if (load.label === label) {
				const date = load.time.toDate()
				const str = dateToHHMM(date)
				list.push({label: load.label, time: str})
			}
		})

		return [...list]
	}

	function Display() {
		if (trucks.length == 0) {
			return (
				<div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
					You can add your first truck by selecting the "Add Truck" option above!
				</div>
			)
		}
		return trucks.map((truck) => {
			return (
				<TruckCard
					key={truck.label}
					label={truck.label}
					type={truck.type}
					color1={truck.color1}
					color2={truck.color2}
					loads={numLoads(truck.label)}
					list={loadList(truck.label)}
					addLoad={props.addLoad}
					subLoad={props.subLoad}
					removeTruck={props.removeTruck}
					perm={props.perm}
				/>
			)
		})
	}

	return (<Display />)
}