const sizes = {
  Tandem: { Clay: 7.5, Marginal: 8, Topsoil: 8, Gravel: 6.5, Asphalt: 5.5, Concrete: 4.5 },
  'End Dump': { Clay: 11, Marginal: 11, Topsoil: 11.5, Gravel: 9.5, Asphalt: 7, Concrete: 6 },
  Tridem: { Clay: 13, Marginal: 13.5, Topsoil: 14, Gravel: 11.5, Asphalt: 9, Concrete: 7 },
  Quad: { Clay: 17, Marginal: 17.5, Topsoil: 18, Gravel: 15, Asphalt: 11, Concrete: 9 },
};

export const getLoadSize = (type, material) => {
	let loadSize = sizes[type][material];
	if (material == "Other") {
		loadSize = sizes[type]["Clay"]
	}
  return loadSize;
};

export const getCubesByType = (type, material, loads) => {
  const loadSize = getLoadSize(type, material);
  const cubes = loads * loadSize;
  return cubes;
};

export const getTotalCubes = (material, loads, trucks) => {
  let cubes = {
		total: 0,
    Tandem: { loads: 0, cubes: 0 },
    'End Dump': { loads: 0, cubes: 0 },
    Tridem: { loads: 0, cubes: 0 },
    Quad: { loads: 0, cubes: 0 },
  };

  loads.forEach((load) => {
    const type = trucks.filter((truck) => truck.label == load.label)[0].type;
		const quantity = getLoadSize(type, material)
		cubes[type].loads += 1;
		cubes[type].cubes += quantity
		cubes.total += quantity
  });

  return {cubes};
};

export const calcDetailedHaulData = (material, trucks, loads) => {
	let data = {
		tandemLoads: 0,
		enddumpLoads: 0,
		tridemLoads: 0,
		quadLoads: 0,
	}

	const filterTrucks = (type) => {
		const filtered = trucks.filter((truck) => truck.type == type);
		const labels = filtered.map((truck) => {return truck.label})
		return labels
	}

	data.sortedTrucks = {
		Tandems: {
			size: getLoadSize('Tandem', material),
			labels: [...filterTrucks('Tandem')],
		},
		Enddumps: {
			size: getLoadSize('End Dump', material),
			labels: [...filterTrucks('End Dump')],
		},
		Tridems: {
			size: getLoadSize('Tridem', material),
			labels: [...filterTrucks('Tridem')],
		},
		Quads: {
			size: getLoadSize('Quad', material),
			labels: [...filterTrucks('Quad')],
		},
	};

	loads.map((load) => {
		if (data.sortedTrucks.Tandems.labels.includes(load.label)) {
			++data.tandemLoads;
		}
		if (data.sortedTrucks.Enddumps.labels.includes(load.label)) {
			++data.enddumpLoads;
		}
		if (data.sortedTrucks.Tridems.labels.includes(load.label)) {
			++data.tridemLoads;
		}
		if (data.sortedTrucks.Quads.labels.includes(load.label)) {
			++data.quadLoads;
		}
	});

	data.tandemCubes = data.tandemLoads * data.sortedTrucks.Tandems.size
	data.enddumpCubes = data.enddumpLoads * data.sortedTrucks.Enddumps.size
	data.tridemCubes = data.tridemLoads * data.sortedTrucks.Tridems.size
	data.quadCubes = data.quadLoads * data.sortedTrucks.Quads.size
	data.totalCubes = data.tandemCubes + data.enddumpCubes + data.tridemCubes + data.quadCubes

	return data
}
