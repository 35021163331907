import { Badge } from 'react-bootstrap';
import dateToHHMM from '../../../../storage/dateToHHMM';

export default function LoadListView(props) {

	const FullLoadListItems = () => {
		const desktopStyles = {			
			containerStyle: {
				width: '175px',
				border: '1px solid grey',
				borderRadius: '10px',
				margin: "10px",
				padding: "2px",
			},
			loadListStyle: {
				display: "flex",
				textAlign: "left",
				justifyContent: "center",
				alignItems: "center",
			},
			loadNumberStyle: {margin: "2px", fontWeight: "bold", fontSize: "110%", textAlign: "center"},
			pillSpanStyle: {margin: "2px"},
		}

		const mobileStyles = {			
			containerStyle: {
				width: 'fit-content',
				border: '1px solid grey',
				borderRadius: '10px',
				margin: "10px",
				padding: "2px",
			},
			loadListStyle: {
				display: "flex",
				textAlign: "left",
				justifyContent: "center",
				alignItems: "center",
			},
			loadNumberStyle: {margin: "2px", fontWeight: "bold", textAlign: "center"},
			pillSpanStyle: {margin: "2px"},
		}

		const getStyles = () => {
			if (props.isMobile) {
				return mobileStyles
			}
			return desktopStyles
		}
	
		return props.loads.map((load, index) => {
			const styles = getStyles()
			const str = dateToHHMM(load.time.toDate());
			return (
				<div style={styles.containerStyle} key={`FullLoadListItem${load.label}${str}`}>
					<div style={styles.loadNumberStyle}>{`Load ${index + 1}`}</div>
					<div style={styles.loadListStyle}>
						<span style={styles.pillSpanStyle}>{"🚛 "}<Badge bg='secondary'>{load.label}</Badge></span>
						<span style={styles.pillSpanStyle}>{"🕓 "}<Badge bg='secondary'>{str}</Badge></span>
					</div>
				</div>
			);
		});
	};
	return (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			<FullLoadListItems />
		</div>
	);
};