
import { Modal, Form, FormLabel, FormControl, Button } from "react-bootstrap"

export default function InfoModal(props) {

	const handleKeypress = e => {
		if (e.code === "Enter" && props.info) {
			props.handleSave();
		}
	}

	return (
		<>
			<Modal show={props.info} onHide={props.handleCancel}>
				<Modal.Header>
					<Modal.Title>Edit Haul Info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormLabel>Supervisor</FormLabel>
						<FormControl defaultValue={props.supe} onChange={(e) => props.setNewSupe(e.target.value)} onKeyDown={handleKeypress} />
						<br />
						<FormLabel>Notes</FormLabel>
						<FormControl as='textarea' rows={3} defaultValue={props.note} onChange={(e) => props.setNewNote(e.target.value)} onKeyDown={handleKeypress} />
						<br />
						<hr />
						<FormLabel>Note: Site and date are not editable. Please create a new haul if these are incorrect.</FormLabel>
					</Form>
				</Modal.Body>
				<Modal.Footer>

					<Button variant="secondary" onClick={props.handleCancel}>
						Cancel
					</Button>
					<Button variant="primary" onClick={props.handleSave}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}