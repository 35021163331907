import { useState } from "react"
import { Button, Form, FormControl, FormLabel, FormSelect } from "react-bootstrap"
import { useNavigate } from "react-router-dom"


export default function NewTruck({ props }) {

	const navigate = useNavigate()
	const [label, setLabel] = useState("")
	const [type, setType] = useState("Quad")
	const [color1, setColor1] = useState("Black")
	const [color2, setColor2] = useState("Black")
	const [company, setCompany] = useState("Hehar Trucking (Raj)")

	const handleCancel = () => {
		setLabel("")
		setType("Quad")
		setColor1("Black")
		setColor2("Black")
		setCompany("Hehar Trucking (Raj)")
		navigate("#")
	}

	const handleAdd = () => {
		const truck = {label: label, type: type, color1: color1, color2: color2, company: company}
		for (const key in props.trucks) {
			if (truck.label === props.trucks[key].label) {
				alert("Truck label already in use!")
				handleCancel()
				return
			}
		}
		props.addTruck(truck)
		handleCancel()
	}

	return(
		<div style={{marginLeft: "10%", marginRight: "10%"}}>
			<Form>
				<FormLabel>Please enter the truck number or label.</FormLabel>
				<FormControl placeholder="e.g. 1234" onChange={(e) => setLabel(e.target.value)}/>
				<br />

				<FormLabel>Please select the truck type.</FormLabel>
				<FormSelect  onChange={(e) => setType(e.target.value)}>
					<option name="Quad">Quad</option>
					<option name="Tridem">Tridem</option>
					<option name="End Dump">End Dump</option>
					<option name="Tandem">Tandem</option>
				</FormSelect>
				<br />

				<FormLabel>Please select the truck color.</FormLabel>
				<FormSelect onChange={(e) => setColor1(e.target.value)}>
					<option name="Black">Black</option>
					<option name="White">White</option>
					<option name="Grey">Grey</option>
					<option name="Blue">Blue</option>
					<option name="Red">Red</option>
					<option name="Orange">Orange</option>
					<option name="Purple">Purple</option>
					<option name="Teal">Teal</option>
				</FormSelect>
				<br />

				<FormLabel>Please select the truck color.</FormLabel>
				<FormSelect onChange={(e) => setColor2(e.target.value)}>
					<option name="Black">Black</option>
					<option name="White">White</option>
					<option name="Grey">Grey</option>
					<option name="Blue">Blue</option>
					<option name="Red">Red</option>
					<option name="Orange">Orange</option>
					<option name="Purple">Purple</option>
					<option name="Teal">Teal</option>
				</FormSelect>
				<br />

				<FormLabel>Please indicate the trucking company. If other, specify in notes.</FormLabel>
				<FormSelect onChange={(e) => setCompany(e.target.value)}>
					<option name="WCL Tandem">WCL Tandem</option>
					<option name='G&S Trucking (Mani)'>G&S Trucking (Mani)</option>
					<option name='Hehar Trucking (Raj)'>Hehar Trucking (Raj)</option>
					<option name='AB Gill Trucking (Bobby)'>AB Gill Trucking (Bobby)</option>
					<option name='Rebel Heart'>Rebel Heart</option>
					<option name='Other'>Other</option>
				</FormSelect>
				<br />

				<Button variant="secondary" onClick={handleCancel}>Cancel</Button> {" "}
				<Button variant="primary" onClick={handleAdd}>Add</Button>
			</Form>
		</div>
	)
}