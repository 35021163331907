import { Table } from 'react-bootstrap';
import { getTotalCubes } from '../../../../functions/calculateCubes';


export default function QuantitiesView (props) {
	// if (selected !== 'quantities') {
	//   return;
	// }

	const { cubes } = getTotalCubes(
		props.haulData.material,
		props.loads,
		props.trucks
	);
	const tableMain = {
		margin: '10px',
		marginTop: '10px',
		// border: '1px solid grey',
		textAlign: 'center',
	};

	const tableHeadRow = {
		// border: '1px solid grey',
	};

	const tableCell = {
		// border: '1px solid grey'
	};

	const MobileTableHandler = (props) => {
		return (
			<Table size="sm" hover responsive>
				{props.children}
			</Table>
		)
	}

	const DesktopTableHandler = (props) => {
		return (
			<Table hover responsive>
				{props.children}
			</Table>
		)
	}

	const ChildrenHandler = () => {
		return (
			<>
				<thead>
					<tr>
						<th>Type</th>
						<th>Loads</th>
						<th>Quantity</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Quad</td>
						<td>{cubes.Quad.loads}</td>
						<td>{cubes.Quad.cubes}</td>
					</tr>
					<tr>
						<td>Tridem</td>
						<td>{cubes.Tridem.loads}</td>
						<td>{cubes.Tridem.cubes}</td>
					</tr>
					<tr>
						<td>End Dump</td>
						<td>{cubes['End Dump'].loads}</td>
						<td>{cubes['End Dump'].cubes}</td>
					</tr>
					<tr>
						<td>Tandem</td>
						<td>{cubes.Tandem.loads}</td>
						<td>{cubes.Tandem.cubes}</td>
					</tr>
				</tbody>
			</>
		)
	}

	if (props.isMobile) {
		return (
			<div style={{ margin: '10px'}}>
				TOTAL HAULED: {cubes.total} m3
				<MobileTableHandler>
					<ChildrenHandler />
				</MobileTableHandler>
			</div>
		)
	}

	return (
		<div style={{ margin: '10px'}}>
			TOTAL HAULED: {cubes.total} m3
			<DesktopTableHandler>
				<ChildrenHandler />
			</DesktopTableHandler>
		</div>
	);
};