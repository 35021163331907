import findHash from "../../functions/findHash"
import RunningTableHandler from "./modes/table"
import InfoView from "./modes/info"
import CardView from "./modes/card"
import NewTruck from "./modes/newtruck"
import SignoutTableHandler from "./modes/signout"
import GraphView from "./modes/graphs"


export default function HaulViewHandler(props) {
	const mode = findHash(window.location.href)
	switch (mode) {
		case "Table":
			return <RunningTableHandler props={{ ...props }} />
		case "Signout":
			return <SignoutTableHandler props={{ ...props }} />
		case "Info":
			return <InfoView props={{ ...props }} />
		case "AddTruck":
			return <NewTruck props={{ ...props }} />
		case "Graphs":
			return <GraphView props={{ ...props }} />
		default:
			return <CardView props={{ ...props }} />
	}
}
