import { getHaulData, getSiteData } from "../storage/requests";


export default function checkEditPermission(site, date, admin, adminOnly) {
	if (admin) {
		return true
	}

	if (adminOnly === false) {
		return true
	}

	return false
}