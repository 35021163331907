import React from 'react';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { getChartColors } from './colors';


const loadRoundTimeDatasets = (trucks, loads) => {
	const colors = getChartColors()
  let newDatasets = [];

  if (loads.length == 0) {
    return;
  }
  trucks.forEach((truck, index) => {
    let newLoads = loads.filter((load) => load.label == truck.label);
    let roundTimes = [];
    for (let i = 1; i < newLoads.length; i++) {
      const time = newLoads[i].time - newLoads[i - 1].time;
      roundTimes.push({ x: newLoads[i].time.toDate(), y: time / 60 });
    }
    const newDatasetItem = {
      label: truck.label,
      data: roundTimes,
      borderColor: colors[index] + ')',
      backgroundColor: colors[index] + ', 0.5)',
    };
    newDatasets.push(newDatasetItem);
  });

  return newDatasets;
};

export default function RoundTimeLineGraph({ trucks, loads }) {
  const DELTA = 5;
  const datasets = loadRoundTimeDatasets(trucks, loads);

  const options = {
    responsive: true,
    scales: {
      y: {
        title: { display: true, text: 'Round Time' },
      },
      x: {
        type: 'time',
        time: {
          unit: 'hour',
        },
        title: { display: true },
        text: 'Hour',
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Truck Round Times',
      },
    },
  };

  const data = { datasets: datasets };
  return <Line id='roundtimes' data={data} options={options} />;
};
