import React, { useState } from 'react';
import 'chartjs-adapter-date-fns';
import { Bubble, Scatter } from 'react-chartjs-2';
import 'chart.js/auto';
import { getChartColors } from './colors';
import { getLoadSize } from '../../../../functions/calculateCubes.js';
import secondsToHHMMSS from '../../../../storage/secondsToHHMMSS.js';
import { Button } from 'react-bootstrap';

function loadQuantityBubbleDatasets(trucks, loads, material, givenTimeSplit, yAxisSetting) {
  const colors = getChartColors();
  const timeSplit = givenTimeSplit * 60; //minutes wanted multiplied by 60 (sec -> min)
  const firstTime = loads[0].time.seconds;
  const lastTime = loads[loads.length - 1].time.seconds;
	const radiusDivisor = 3;
  const splitLoads = [];
  const quantitySplits = [];
  let totalQuantity = 0;
  let newDatasets = [];

  for (let i = firstTime; i <= lastTime; i += timeSplit) {
    let thisSplitTotalQuantity = 0;

    const splitFilter = (time) => {
      return time + 100 <= i && time >= i - timeSplit;
    };

    const newSplit = loads.filter((load) => splitFilter(load.time.seconds));
    if (newSplit.length == 0) {
      continue;
    }
    const timeSplitDate = newSplit[0].time.toDate();
    newSplit.forEach((load) => {
      const type = trucks.filter((truck) => truck.label == load.label)[0].type;
      const size = getLoadSize(type, material);
      load = { ...load, size: size };
      thisSplitTotalQuantity += size;
      totalQuantity += size;
    });
    splitLoads.push(newSplit);
		if (yAxisSetting == 'quantity') {quantitySplits.push({ x: timeSplitDate, y: totalQuantity, r: thisSplitTotalQuantity / radiusDivisor });}
    if (yAxisSetting == 'trucks') {quantitySplits.push({ x: timeSplitDate, y: newSplit.length, r: thisSplitTotalQuantity / radiusDivisor });}
  }
  const newDatasetItem = {
		id: yAxisSetting+givenTimeSplit,
    label: 'Quantity',
    data: quantitySplits,
    borderColor: colors[10] + ')',
    backgroundColor: colors[10] + ', 0.5)',
  };

  newDatasets.push(newDatasetItem);
  return newDatasets;
}

export default function QuantityBubbleGraph({ trucks, loads, material }) {
	const [timeSplit, setTimeSplit] = useState(20)
	const [yAxisSetting, setYAxisSetting] = useState('quantity')
	const timeSplitChangeAmount = 5

	const incrementSplit = () => {setTimeSplit(timeSplit + timeSplitChangeAmount)}
	const decrementSplit = () => {
		if (timeSplit - timeSplitChangeAmount <= 0) {return}
		setTimeSplit(timeSplit - timeSplitChangeAmount)
	}

	const toggleYAxisSetting = () => {
		if (yAxisSetting == 'quantity') {setYAxisSetting('trucks'); return;}
		setYAxisSetting('quantity')
	}

	const yAxisToggleButtonLabel = () => {
		if (yAxisSetting == 'quantity') {return 'Quantity'}
		if (yAxisSetting == 'trucks') {return 'Trucks'}
		return 'Error'
	}

	const yAxisLabel = () => {
		if (yAxisSetting == 'quantity') {return 'Quantity'}
		if (yAxisSetting == 'trucks') {return 'Number of Trucks'}
		return 'Error'
	}

	const datasets = loadQuantityBubbleDatasets(trucks, loads, material, timeSplit, yAxisSetting);

  const options = {
    responsive: true,
    scales: {
      y: {
        title: { display: true, text: yAxisLabel() },
      },
      x: {
        type: 'time',
        time: {
          unit: 'hour',
        },
        title: { display: true, text: 'Time of Load' },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total Quantity by Time Group over Time of Load',
      },
    },
  };

  const data = { datasets: datasets };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <div style={{ margin: '5px' }}><Button variant='outline-primary' onClick={() => incrementSplit()}>+</Button></div>
					<div style={{ margin: 'auto', marginLeft: '5px', marginRight: '5px', width: 'max', height: 'max' }}>{timeSplit}</div>
          {timeSplit-timeSplitChangeAmount<=0?<div style={{ margin: '5px' }}><Button disabled variant='outline-secondary' onClick={() => decrementSplit()}>-</Button></div>:<div style={{ margin: '5px' }}><Button variant='outline-primary' onClick={() => decrementSplit()}>-</Button></div>}
          <div style={{ margin: '5px' }}><Button variant='outline-primary' onClick={toggleYAxisSetting}>{yAxisToggleButtonLabel()}</Button></div>
        </div>
      </div>
      <Bubble id='quantitytimes' data={data} options={options} />
    </>
  );
}
