import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import dateToHHMM from '../../../storage/dateToHHMM';
import secondsToHHMMSS from '../../../storage/secondsToHHMMSS';
import secondsToMMSS from '../../../storage/secondsToMMSS';
import './table.css'
function LoadListModal(props) {
  const LoadListItem = (props) => {
    const time = new Date(props.load.time);
    const str = dateToHHMM(time);
    return <li>{str}</li>;
  };

  return (
    <>
      <span>
        <Button variant='outline-primary' onClick={props.handleOpen}>
          {props.loads}
        </Button>
      </span>
      <Modal show={props.showList} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Load List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='loadlistmodal'>
            <h3>{props.label}</h3>
            <ol>
              {props.list.map((load, index) => {
                return <LoadListItem key={load.label + index} load={load} />;
              })}
            </ol>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function RunningTableMain(props) {
  const tableMain = {
    margin: '10px',
    marginTop: '10px',
    border: '1px solid grey',
    textAlign: 'center',
  };

  const tableHeadRow = {
    border: '1px solid grey',
  };

  const tableHeadRowButton = {
    border: '1px solid grey',
    width: '15%',
  };

  return (
    <Table bordered hover responsive style={tableMain}>
      <thead>
        <tr>
          <th style={tableHeadRowButton} colSpan={2}>
            Buttons
          </th>
          <th style={tableHeadRow} rowSpan={2}>
            Label
          </th>
          <th style={tableHeadRow} rowSpan={2}>
            Loads
          </th>
          <th style={tableHeadRow} rowSpan={2}>
            Type
          </th>
          <th style={tableHeadRow} rowSpan={2}>
            Away Time
          </th>
          <th style={tableHeadRow} rowSpan={2}>
            Last Round Time
          </th>
        </tr>
        <tr>
          <th style={tableHeadRow}>Add</th>
          <th style={tableHeadRow}>Sub</th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  );
}

function RunningTableRow(props) {
  const [time, setTime] = useState(Date.now());
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const tableCell = {
    border: '1px solid grey',
  };

  const labelBorderColor = {
    border: '1px solid grey',
    background: 'linear-gradient(to right,' + props.color1 + ' 50%, ' + props.color2 + ' 50%)',
  };

  const labelWhiteBack = {
    border: '1px solid grey',
    margin: '10px',
    padding: '0px',
    backgroundColor: 'white',
  };

  const handleClose = () => {
    setShowList(false);
  };

  const handleOpen = () => {
    setShowList(true);
  };

  const AwayTime = () => {
    if (props.finished) {
      return 'Haul Finished';
    }

    const loads = [...props.list];
    if (loads.length === 0) {
      return 'No Loads';
    }

    const last = loads[loads.length - 1].time;
    const now = new Date();
    const sec = Math.floor((now - last) / 1000);

    const str = secondsToHHMMSS(sec);

    return str;
  };

  const LastRoundTime = () => {
    const loads = [...props.list];
    if (loads.length === 0) {
      return 'No Loads';
    }
    if (loads.length === 1) {
      return 'First Load';
    }

    const last = loads[loads.length - 1].time;
    const penul = loads[loads.length - 2].time;
    const sec = Math.floor((last - penul) / 1000);

    const str = secondsToHHMMSS(sec);
    return str;
  };

  return (
    <tr key={'row' + props.label}>
      <td style={tableCell}>
        <Button disabled={props.perm ? false : true} variant='outline-primary' onClick={() => props.addLoad(props.label)}>
          +
        </Button>
      </td>
      <td style={tableCell}>
        <Button disabled={props.perm ? false : true} variant='outline-primary' onClick={() => props.subLoad(props.label)}>
          -
        </Button>
      </td>
      <td style={labelBorderColor}>
        <div style={labelWhiteBack}>{props.label}</div>
      </td>
      <td style={tableCell}>
        <LoadListModal showList={showList} handleOpen={handleOpen} handleClose={handleClose} list={props.list} label={props.label} loads={props.loads} />
      </td>
      <td style={tableCell}>{props.type}</td>
      <td>
        <AwayTime />
      </td>
      <td>
        <LastRoundTime />
      </td>
    </tr>
  );
}

export default function RunningTableHandler({ props }) {
  const trucks = props.trucks;
  const loads = props.loads;

  const numLoads = (label) => {
    let num = 0;
    loads.map((load) => {
      if (load.label === label) {
        num++;
      }
    });
    return num;
  };

  const loadList = (label) => {
    let list = [];
    loads.map((load) => {
      if (load.label === label) {
        const date = load.time.toDate();
        list.push({ label: load.label, time: date });
      }
    });

    return [...list];
  };

  const populateRows = trucks.map((truck) => {
    return (
      <RunningTableRow
        key={truck.label}
        finished={props.haulData.adminOnly}
        label={truck.label}
        type={truck.type}
        loads={numLoads(truck.label)}
        list={loadList(truck.label)}
        color1={truck.color1}
        color2={truck.color2}
        addLoad={props.addLoad}
        subLoad={props.subLoad}
        perm={props.perm}
      />
    );
  });

  return <RunningTableMain>{populateRows}</RunningTableMain>;
}
