
import { Badge, Button, Col, Container, ListGroup, ListGroupItem, Row, Stack, Table } from 'react-bootstrap';
import DetailsView from './info/details';
import { useState } from 'react';
import LoadListView from './info/loadlist';
import QuantitiesView from './info/quantities';

export default function InfoView({ props }) {

	// TODO: This view (especially the DetailsView section) is VERY non-responsive.
	// Probably fix that at some point.

	const CombinedInfoView = () => {
		const rowStyle = {marginTop: "30px", marginBottom: "30px"}
		const colStyle = {overflow: "scroll"}
		const isMobile = props.width <= 768

		const DesktopHandler = () => {
			return (
			<div style={{ marginLeft: "5%", marginRight: "5%", height: "100%", display: 'flex'}}>
				<Container fluid>
					<Row style={rowStyle}>
						<Col style={colStyle}>
							<DetailsView isMobile={false} {...props} />
						</Col>
						<Col style={colStyle}>
							<QuantitiesView isMobile={false} {...props} />
						</Col>
					</Row>
					<Row style={rowStyle}>
						<Col style={colStyle}>
							<LoadListView isMobile={false} {...props} />
						</Col>
					</Row>
				</Container>
			</div>
			)
		}

		const MobileHandler = () => {
			return (
				<div style={{display: "block", marginLeft: "10px", marginRight: "10px"}}>
					<DetailsView isMobile={true} {...props} />
					<QuantitiesView isMobile={true} {...props} />
					<LoadListView isMobile={true} {...props} />
				</div>
			)
		}

		if (isMobile) {
			return <MobileHandler />
		}

		return <DesktopHandler />

	}

	return <CombinedInfoView />

}
