import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, useLoaderData, useOutletContext } from 'react-router-dom';
import setTestData from '../firebase/setTestData';
import siteDateCompare from '../functions/siteDateCompare';
import { getAllData } from '../storage/requests';
import { calcDetailedHaulData } from '../functions/calculateCubes';

export default function All() {
  const { data } = useLoaderData(); // just... all the data.
  const [admin, setAdmin] = useOutletContext();
  const [showInactive, setShowInactive] = useState(false);
	const [inactiveToggleVariant, setInactiveToggleVariant] = useState('outline-secondary')
  const [sort, setSort] = useState('site');
	const [selectedMaterial, setSelectedMaterial] = useState("All")

  const linkStyle = {
    // should really save this somewhere else and use it a lot more
    textDecoration: 'none',
    color: 'black',
  };

	const updateInactiveToggleVariant = () => {
		if (showInactive) {
			setInactiveToggleVariant('danger')
			return
		}

		if (admin) {
			setInactiveToggleVariant('outline-primary')
			return
		}

		setInactiveToggleVariant('outline-secondary')
	}

	useEffect(() => {
		updateInactiveToggleVariant()
	}, [showInactive, admin])

  const toggleShowInactive = () => {
    if (admin && !showInactive) {
      setShowInactive(true);
    }
    if (showInactive) {
      setShowInactive(false);
    }
    return;
  };

	const MaterialSelectorButtons = () => {
		const handleMaterialSelectorButtonClick = (material) => {
			setSelectedMaterial(material)
		}
		const divStyle = {
			display: "flex",
			width: "100%",
			justifyContent: "center"
		}
		const buttonStyle = {
			margin: "5px"
		}
		return (
			<div style={divStyle}>
				<Button style={buttonStyle} variant={selectedMaterial=="All"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('All')}>All</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Clay"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Clay')}>Clay</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Marginal"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Marginal')}>Marginal</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Topsoil"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Topsoil')}>Topsoil</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Gravel"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Gravel')}>Gravel</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Asphalt"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Asphalt')}>Asphalt</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Concrete"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Concrete')}>Concrete</Button>
				<Button style={buttonStyle} variant={selectedMaterial=="Other"?"primary":"outline-secondary"} onClick={() => handleMaterialSelectorButtonClick('Other')}>Other</Button>
			</div>
		)
	}

  const InactiveToggleButton = (variant, text) => {
    if (!admin) {
      return (
        <Button style={{ margin: '5px', padding: '5px', width: '100%', display: 'block' }} onClick={toggleShowInactive} variant={inactiveToggleVariant} disabled>
          {showInactive ? 'Hide Inactive' : 'Show Inactive'}
        </Button>
      );
    }

    return (
      <Button style={{ margin: '5px', padding: '5px', width: '100%', display: 'block' }} onClick={toggleShowInactive} variant={inactiveToggleVariant} >
        {showInactive ? 'Hide Inactive' : 'Show Inactive'}
      </Button>
    );
  };

  const DisplayHauls = () => {
    // fills in table with each haul.
    let toDisplay = [];

    for (const site in data.sites) {
			let siteData = [];
			if (data.sites[site].active || showInactive) {
				for (const date in data.sites[site].hauls) {
					if (selectedMaterial == "All" || data.sites[site].hauls[date].material == selectedMaterial) {
					let toAdd = { site: site, date: date };
					siteData.push(toAdd);
					}
				}
			}
      siteData.sort(siteDateCompare); //sorts by site then by date.
      toDisplay = [...toDisplay, ...siteData];
    }

    if (sort === 'date') {
      toDisplay.sort(siteDateCompare);
    } // date-only sorting, doesn't keep sites together

    return toDisplay.map((haul) => {
			const siteData = data.sites[haul.site].hauls[haul.date]
			const detailData = calcDetailedHaulData(siteData.material, siteData.trucks, siteData.loads)
			const rowStyle = {
				backgroundColor: !data.sites[haul.site].active?"rgba(255,0,0,0.5)":"transparent"
			}

			let siteName = haul.site
			if (!data.sites[haul.site].active) {
				siteName += ' (INACTIVE)'
			}
      return (
        <tr style={rowStyle} key={`haulrow${haul.site}${haul.date}`}>
          <td>
            <Link to={`/site/${haul.site}`}>{siteName}</Link>
          </td>
          <td>
            <Link to={`/haul/${haul.site}/${haul.date}`}>{haul.date}</Link>
          </td>
					<td>{data.sites[haul.site].hauls[haul.date].material}</td>
          <td>{data.sites[haul.site].hauls[haul.date].trucks.length}</td>
          <td>{data.sites[haul.site].hauls[haul.date].loads.length}</td>
					<td>{'' + detailData.totalCubes + " m3"}</td>
        </tr>
      );
    });
  };

  return (
    <div style={{ margin: '5px', marginLeft: '25px', marginRight: '25px' }}>
			<MaterialSelectorButtons />
			<InactiveToggleButton />
      <Table hover responsive bordered striped size='sm'>
        <thead>
          <tr>
            <td onClick={() => setSort('site')} style={{ cursor: 'pointer' }}>
              Site Name
            </td>
            <td onClick={() => setSort('date')} style={{ cursor: 'pointer' }}>
              Date
            </td>
						<td>Material</td>
            <td># Trucks</td>
            <td># Loads</td>
						<td>Quantity</td>
          </tr>
        </thead>
        <tbody>
          <DisplayHauls />
        </tbody>
      </Table>
    </div>
  );
}

export async function loader({ params }) {
  const data = await getAllData();
  return { data };
}
